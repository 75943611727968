var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-1",
      "centered": "",
      "title": "Tukar Kata Laluan",
      "ok-title": "Tukar",
      "cancel-title": "Batal"
    },
    on: {
      "ok": _vm.changePassword
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "password"
    }
  }, [_vm._v(_vm._s(_vm.$t("new.password")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "new_password",
      "id": "new_password"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "password"
    }
  }, [_vm._v(_vm._s(_vm.$t("confirm-password")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password_confirmation,
      expression: "password_confirmation"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "confirm_password",
      "id": "confirm_password"
    },
    domProps: {
      "value": _vm.password_confirmation
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.password_confirmation = $event.target.value;
      }
    }
  })]), _c('small', [_c('i', [_vm._v(_vm._s(_vm.$t("info-noti-email")))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }