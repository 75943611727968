var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("staff1")) + " Amanah Warisan Berhad")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card public-profile border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-3 text-md-left text-center"
  }, [_vm.profile_picture ? _c('div', [_c('img', {
    staticClass: "\n                        avatar avatar-large\n                        rounded-circle\n                        shadow\n                        d-block\n                        mx-auto\n                      ",
    attrs: {
      "src": _vm.profile_picture,
      "alt": "",
      "height": "150"
    }
  }), _c('input', {
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })]) : _vm._e(), !_vm.profile_picture ? _c('div', [_c('img', {
    staticClass: "\n                        avatar avatar-large\n                        rounded-circle\n                        shadow\n                        d-block\n                        mx-auto\n                      ",
    attrs: {
      "src": _vm.profile_picture,
      "alt": "",
      "height": "150"
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "col-lg-9 col-md-9"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('div', {
    staticClass: "col-md-12 text-md-left text-center mt-4 mt-sm-0"
  }, [_c('h3', {
    staticClass: "title mb-0"
  }, [_vm._v(_vm._s(_vm.user.name))]), _c('small', {
    staticClass: "text-muted h6 mr-2"
  }, [_vm._v(" " + _vm._s(_vm.user.role) + " ")]), _c('ul', {
    staticClass: "list-inline mb-0 mt-3"
  }, [_c('li', {
    staticClass: "list-inline-item mr-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'client.edit',
        params: {
          client_id: _vm.clientId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.update-details")) + " "), _c('i', {
    staticClass: "mdi mdi-chevron-right"
  })])], 1)])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("email")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.email ? _vm.user.email : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("gender")))]), _vm.user.gender == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("male")) + " ")]) : _vm._e(), _vm.user.gender == 2 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("female")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.ic_number ? _vm.user.ic_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("old-ic")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.old_ic_number ? _vm.user.old_ic_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.mobile_number ? _vm.user.mobile_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.office_phone_number ? _vm.user.office_phone_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.fullAddress ? _vm.fullAddress : "-") + " ")])])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left"
  }), _vm._v(_vm._s(_vm.$t("back")) + " ")]), _c('div', [['Staff'].includes(this.userRole) ? _c('button', {
    staticClass: "btn btn-primary mr-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.resendEmail(_vm.clientId);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send-noti-email")) + " ")]) : _vm._e(), ['Staff'].includes(this.userRole) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.changePasswordModal.showModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.change-password")) + " ")]) : _vm._e()])])])])])])])]), _c('ChangePasswordModal', {
    ref: "changePasswordModal"
  }), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }